<template> 
    <div>
        <v-row class="ma-0" align="center" justify="center">
            <v-icon :color="color" class="mt-2">{{icon}}</v-icon>
        </v-row>
    </div>
</template>

<script>   

 
export default {

    props: {

    },
    

    data() { 
        return {
            cellValue: null
        }
    },
    
    computed: {
        icon(){
            if(this.params.icon)
                return this.params.icon //: "mdi-apps";
            else if(this.params.icons)
                return this.params.icons[this.cellValue].icon ? this.params.icons[this.cellValue].icon :"mdi-cancel"
            else 
                return "mdi-apps"

        },
        color(){
            if(this.params.color)
                return this.params.color //: "mdi-apps";
            else if(this.params.icons)
                return this.params.icons[this.cellValue].color ? this.params.icons[this.cellValue].color :"grey"
            else 
                return "grey"

        }
    },
    
    methods: {

        getValueToDisplay(params) {
            return params.valueFormatted ? params.valueFormatted : params.value;
        },

        getIcon(params) {
            return params.icon ? params.icon : this.icon;
        },
    },
    beforeMount() {
        // this.params contains the cell and row information and is made available to this component at creation time
        // see ICellRendererParams for more details
        console.debug("beforeMount", this.params)
        this.cellValue = this.getValueToDisplay(this.params);
    },    

}
</script>