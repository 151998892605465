<template>
    <v-card
        class="mb-12"
        min-height="500"
    >  
    <v-card :height=dropZoneHeight style="width: 100%">
        <div ref="producttable" class="inner-col" style="width: 100%; height: 100%;">
            <ag-grid-vue              
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"     
                :rowDragManaged="true"
                :suppressMoveWhenRowDragging="true"
                :animateRows="true"                   
                :defaultColDef="defaultColDef"
                :defaultColGroupDef="defaultColGroupDef"
                :columnDefs="columnDefsDropZone"
                :rowData="rowDataDropZone" 
                :suppressDragLeaveHidesColumns=true                     
                @grid-ready="onGridReady($event, 'dropZone')">
            </ag-grid-vue>
        </div>
        <v-alert class="mt-n14" type="error" v-if="errMessage != ''">
            {{errMessage}}
        </v-alert>   
    </v-card>
    <v-list>
        <template v-for="(n, i) in products">
            <v-list-group
                v-if="true"
                :key="`${i}-b`"
                v-model="n.active"
                class="green lighten-4 mb-2"
                dark
                permanent 
              >
            <template v-slot:activator>
                <v-list-item-title class="ml-2">{{n.caption}}</v-list-item-title>
            </template>
            <v-list-item class="pa-0">
                <div className="" id="products"   style="width: 100%; height: 350px;">
                    <ag-grid-vue

                        style="width: 100%; height: 100%;"
                        class="products ag-theme-alpine"                        
                        :defaultColDef="defaultColDef"
                        :defaultColGroupDef="defaultColGroupDef"
                        :columnDefs="n.columnDefs"
                        :rowData="n.rowData"
                        :suppressDragLeaveHidesColumns=true
                        @grid-ready="onGridReady($event, 'fungizid')">
                    </ag-grid-vue>
                    </div>
            </v-list-item>    
            </v-list-group>
        </template>
    </v-list>

       
    <v-container v-if="false"><cellButton></cellButton><cellIcon></cellIcon><cellBar></cellBar></v-container>
    </v-card>
</template>

<script>
 import { AgGridVue } from 'ag-grid-vue';
 import CellButton from '../../VueControls/ag-grid/cellButton.vue';
 import CellIcon from '../../VueControls/ag-grid/cellIcon.vue';
 import CellBar from '../../VueControls/ag-grid/cellBar.vue';

export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {

                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        },

        isEdit:{                
            required: true,
            default: false,
            type: Boolean
        },

        isVisible:{                
            required: true,
            default: false,
            type: Boolean
        }
    },

    components:{
        AgGridVue,
        cellButton: CellButton,
        cellIcon: CellIcon,
        cellBar: CellBar
             
    },

    data() {
        return {
            valid: false, 
            errMessage:"",
            search: '',
            apidropZone: null,
            products:[
                {
                    name:"Fungizid", 
                    caption:"Fungizid",
                    columnDefs: 
                    [
                        {
                            headerName: 'Produkt',
                            field: 'product' ,
                            rowDrag: true
                        },
                        {
                            headerName: 'Wirkstoff',
                            field: 'wirkstoff'
                        },
                        {
                            headerName: 'Basis Aufwand',
                            field: 'basisaufwand',
                            width:80
                        
                        },
                        {
                            headerName: 'Einheit',
                            field: 'einheit',
                            width:60
                        
                        },
                        {
                            headerName: 'Odium',
                            children: [
                                { 
                                    headerName: 'Vorübergehend', field:"odium.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                                { headerName: 'Kurativ', field:"odium.kurativ", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                            ]
                        },
                        {
                            headerName: 'Peronospora',
                            children: [
                                { headerName: 'Vorübergehend', field:"peronospora.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                                { headerName: 'Kurativ', field:"peronospora.kurativ", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                            ]
                        },
                        {
                            headerName: 'Phomopsis',
                            children: [
                                { headerName: 'Vorübergehend', field:"phomopsis.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                                { headerName: 'Kurativ', field:"phomopsis.kurativ", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                            ]
                        },
                        {
                            headerName: 'Roter Brenner',
                            children: [
                                { headerName: 'Vorübergehend', field:"roterbrenner.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                                { headerName: 'Kurativ', field:"roterbrenner.kurativ", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                            ]
                        },
                        {
                            headerName: 'Botrytis',
                            children: [
                                { headerName: 'Vorübergehend', field:"botrytis.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                },
                            ]
                        },
                        {
                            headerName: 'Schwarzfäule', field:"schwarzfaeule.vor", width:50, 
                                    cellRenderer: 'cellBar',
                                    cellStyle: {
                                        textAlign: 'left',
                                        marginLeft: '0px',
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }
                                
                            
                        },
                        {
                            headerName: 'Wirkungsweise',
                            children: [
                                { 
                                    headerName: 'Kontakt', field:"wirkunksweise.kontakt", width:50, 
                                    cellRenderer: 'cellIcon', 
                                    cellRendererParams: { icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}
                                },
                                { headerName: 'Lokalsystematisch', field:"wirkunksweise.lokalsystematisch", width:50, cellRenderer: 'cellIcon', cellRendererParams: { icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}},
                                { headerName: 'Systematisch', field:"wirkunksweise.systematisch", width:50, cellRenderer: 'cellIcon', cellRendererParams: { icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}},
                            ]
                        },
                        {
                            headerName: 'Anwendungen max',
                            field: 'anwenungen',
                            width:50
                        }, 
                        {
                            headerName: 'Raubmilben',
                            field: 'rm',
                            width:50
                        },
                        {
                            headerName: 'Bienen',
                            field: 'bienen',
                            width:50,
                            cellStyle: {paddingRight: "0px"}
                        },
                        {
                            headerName: 'Wartezeit',
                            field: 'wartezeit',
                            width:70
                        },      
                        {
                            headerName: 'Gewässerabstand',
                            children: [
                                { headerName: 'Standard', field:"gewAbstand.standard", width:70},
                                { headerName: '50% m', field:"gewAbstand.50", width:70},
                                { headerName: '75% m', field:"gewAbstand.75", width:70},
                                { headerName: '100% m', field:"gewAbstand.100", width:70},
                            ]
                        },
                        {
                            headerName: 'Zulassung',
                            children: [
                                { headerName: 'Tafeltrauben', field:"zulassung.tafeltrauben", width:50, cellRenderer: 'cellIcon', cellRendererParams: { icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}},
                                { headerName: 'Ökologisch', field:"zulassung.oekologisch", width:50, cellRenderer: 'cellIcon', cellRendererParams:{ icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}},
                                { headerName: 'Naturschutzgebiet', field:"zulassung.naturschutzgebiet", width:50, cellRenderer: 'cellIcon', cellRendererParams: { icons:[{icon:"mdi-close-circle-outline", color:"grey"},{icon:"mdi-check-circle-outline" , color:"green"}]}},
                                { headerName: 'Zugelassen bis', field:"zulassung.zugelassenBis", width:150},
                            ]
                        },           
                    ], 
                    rowData:
                    [
                        {
                            id:1, 
                            product: "Flopan 80WDG",
                            wirkstoff: "Flopet", 
                            basisaufwand: "200",
                            einheit: "l/ha", 
                            odium:{ vor:100, kurativ:50}, 
                            peronospora:{ vor:0, kurativ:0}, 
                            phomopsis:{ vor:0, kurativ:0}, 
                            roterbrenner:{ vor:0, kurativ:0}, 
                            botrytis:{ vor:0}, 
                            schwarzfaeule:{ vor:0}, 
                            wirkunksweise:{kontakt:1,lokalsystematisch:0,systematisch:0},                    
                            zulassung:{tafeltrauben:1,oekologisch:0, naturschutzgebiet:0, zugelassenBis:"31.07.2024"},
                            gewAbstand:{standard: 15,"50":15,"75":10,"100":5},
                            anwenungen:5,
                            rm:1,
                            bienen:"B4",
                            wartezeit:10
                        
                        },
                        {
                            id:2, 
                            product: "Bla",
                            wirkstoff: "Flopet", 
                            basisaufwand: "200",
                            einheit: "l/ha", 
                            odium:{ vor:100, kurativ:50}, 
                            peronospora:{ vor:0, kurativ:0}, 
                            phomopsis:{ vor:0, kurativ:0}, 
                            roterbrenner:{ vor:0, kurativ:0}, 
                            botrytis:{ vor:0}, 
                            schwarzfaeule:{ vor:0}, 
                            wirkunksweise:{kontakt:1,lokalsystematisch:0,systematisch:0},                    
                            zulassung:{tafeltrauben:1,oekologisch:0, naturschutzgebiet:0, zugelassenBis:"31.07.2024"},
                            gewAbstand:{standard: 15,"50":15,"75":10,"100":5},
                            anwenungen:5,
                            rm:1,                            
                            bienen:"B4",
                            wartezeit:10
                        
                        },
        
                    ],    
                    activ:false
                },
                {
                    name:"Insektizid", 
                    caption:"Insektizid",
                    columnDefs: 
                    [
                        {
                            headerName: 'Produkt',
                            field: 'product' ,
                            rowDrag: true
                        },
                        {
                            headerName: 'Wirkstoff',
                            field: 'wirkstoff'
                        },
                        {
                            headerName: 'Basis Aufwand kg l/ha',
                            field: 'basisaufwand',
                            width:80
                        
                        },
                        {
                            headerName: 'Aufwand kg l/ha',
                            field: 'aufwand',
                            width:80
                        
                        },                
                        {
                            headerName: 'Traubenwickler',
                            field: 'traubenwickler',
                            width:50
                        
                        },               
                        {
                            headerName: 'Spinnmilben',
                            field: 'spinnmilben',
                            width:50
                        
                        },              
                        {
                            headerName: 'Essigfliege',
                            field: 'essigfliege',
                            width:50
                        
                        },             
                        {
                            headerName: 'Zikaden',
                            field: 'zikaden',
                            width:50
                        
                        },             
                        {
                            headerName: 'Eulenarten',
                            field: 'eulenarten',
                            width:50
                        
                        },             
                        {
                            headerName: 'Thripse',
                            field: 'tripse',
                            width:50
                        
                        },             
                        {
                            headerName: 'Schildläuse',
                            field: 'schildlaeuse',
                            width:50
                        
                        },            
                        {
                            headerName: 'Kräuselmilben',
                            field: 'kraeuselmilben',
                            width:50
                        
                        },          
                        {
                            headerName: 'Rhombenspanner',
                            field: 'rhombenspanner',
                            width:50
                        
                        },          
                        {
                            headerName: 'Ohrwurm',
                            field: 'ohrwurm',
                            width:50
                        
                        },         
                        {
                            headerName: 'Springwurm',
                            field: 'springwurm',
                            width:50
                        
                        },
                        
                        {
                            headerName: 'Gewässerabstand',
                            children: [
                                { headerName: 'Standard', field:"gewAbstand.standard", width:70},
                               { headerName: '50% m', field:"gewAbstand.50", width:70},
                                { headerName: '75% m', field:"gewAbstand.75", width:70},
                                { headerName: '100% m', field:"gewAbstand.100", width:70},
                            ]
                        },
                        {
                            headerName: 'Anwendungen max',
                            field: 'anwenungen',
                            width:50
                        }, 
                        {
                            headerName: 'Raubmilben',
                            field: 'rm',
                            width:50
                        },
                        {
                            headerName: 'Bienen',
                            field: 'bienen',
                            width:80
                        },
                        {
                            headerName: 'Wartezeit',
                            field: 'wartezeit',
                            width:70
                        },              
                    ],
                    rowData:
                    [
                        {
                            id:20, 
                            product: "Mimic",
                            wirkstoff: "Tebofenizid", 
                            basisaufwand: "0.2",                     
                            aufwand: "", 
                            traubenwickler:1,
                            spinnmilben:0,
                            essigfliege:0,
                            zikaden:0,
                            eulenarten:1,
                            tripse:0,
                            schildlaeuse:0,
                            kraeuselmilben:0,
                            rhombenspanner:1,
                            ohrwurm:0,
                            springwurm:1,
                            gewAbstand:{standard: 15,"50":15,"75":10,"100":5},
                            rm:1,
                            bienen:"B4",
                            anwenungen:5,
                            wartezeit:21
                        },
        
                    ],    
                    activ:false
                },
 
                {
                    name:"Herbizid", 
                    caption:"Herbizid",
                    columnDefs: 
                    [
                        {
                        headerName: 'Produkt',
                        field: 'product',
                        rowDrag: true 
                        },
                        {
                            headerName: 'Wirkstoff',
                            field: 'wirkstoff'
                        },
                        {
                            headerName: 'Ganzflächenaufwand kg l/ha',
                            field: 'basisaufwand',
                            width:80
                        
                        },
                        {
                            headerName: 'Aufwand unter Stock* kg l/ha',
                            field: 'aufwand',
                            width:80
                        
                        },                
        
                        {
                            headerName: 'Bodenwirkung',
                            field: 'bodenwirkung',
                            width:50
                        
                        },         
                        {
                            headerName: 'Kontaktwirkung',
                            field: 'kontaktwirkung',
                            width:50,
                            cellRenderer: 'cellIcon',
                            cellRendererParams: {
                            icons:["mdi-close-circle-outline","mdi-check-circle-outline"]
                            }
                        
                        },       
                        {
                            headerName: 'Systematischewirkung',
                            field: 'systematischewirkung',
                            width:50,
                            cellRenderer: 'cellIcon',
                            cellRendererParams: {
                            icons:["mdi-close-circle-outline","mdi-check-circle-outline"]
                            }
                        
                        },                       
                        {
                            headerName: 'Anwendunge ab Jahr',
                            field: 'anwendungenabjahr',
                            width:50
                        
                        },
                        
                        {
                            headerName: 'Gewässerabstand',
                            children: [
                                { headerName: 'Standard', field:"gewAbstand.standard", width:70},
                                { headerName: '50% m', field:"gewAbstand.50", width:70},
                                { headerName: '75% m', field:"gewAbstand.75", width:70},
                                { headerName: '100% m', field:"gewAbstand.100", width:70},
                            ]
                        }, 
                        {
                            headerName: 'Anwendungen max',
                            field: 'anwenungen',
                            width:50
                        },
                        {
                            headerName: 'Wartezeit',
                            field: 'wartezeit',
                            width:70
                        },      
                    ], 
                    rowData:
                    [
                        {
                            id:30, 
                            product: "Kerb Flo",
                            wirkstoff: "Probzymid", 
                            basisaufwand: "6.25",                     
                            aufwand: "", 
                            bodenwirkung:1,
                            kontaktwirkung:0,
                            systematischewirkung:0,
                            anwendungenabjahr:"2",
                            gewAbstand:{standard: 15,"50":15,"75":10,"100":5},
                            anwenungen:5,
                            wartezeit:21
                        },
        
                    ],    
                    activ:false
                }
            ],
            defaultColDef: {
                // set the default column width
                initialWidth: 150,
                // make every column editable
                //editable: true,
                // make every column use 'text' filter by default
                //filter: 'agTextColumnFilter',
                // enable floating filters by default
                //floatingFilter: true,
                // make columns resizable
                resizable: true,

                wrapHeaderText: true,
                autoHeaderHeight: true,
                suppressDragLeaveHidesColumns:true,
  
            },
            defaultColGroupDef: {
                resizable: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },  

            
            columnDefsDropZone: [
                {
                    field: '',
                    width:50,
                    cellRenderer: 'cellButton',
                    cellRendererParams: {
                      clickEvent: this.click,
                      icon:"mdi-delete"
                    },
                    cellStyle: {paddingRight: "0px"}
                },
                {
                    headerName: 'Produkt',
                    field: 'product' ,
                },
                {
                    headerName: 'Wirkstoff',
                    field: 'wirkstoff'
                },               
                
            ],

            rowDataDropZone:[],
        }

    },

    computed:{
  
        dropZoneHeight(){
            
            const tmp = ((this.rowDataDropZone.length) * 40)
            return 120 + tmp
        }

    },

    methods:{
        click(params){
            console.debug("click",params)
            const idx = this.rowDataDropZone.findIndex(item => item.id === params.id);
            if(idx >= 0){
                this.rowDataDropZone.splice(idx,1)
            }
        },

        onGridReady(params, type) {
            console.debug("onGridReady", type)
            const api = params.api;
            switch (type) {
                case "dropZone":
                    this.apiDropZone = api
                    break;
            
                    
                default:
                    break;
            }
  
            if (this.apiDropZone) {
                this.addDropZones(params);
            }
        },  
 

        addDropZones(params) {
            console.debug("addDropZones", params)
            var dropZone = {
                getContainer: () => {
                    return this.$refs.producttable
                },
                onDragStop: (params) => {
                    console.debug("onDragStop", params)
                    const idx = this.rowDataDropZone.findIndex(item => item.id === params.node.data.id);
                    if(idx >= 0){
                        this.errMessage = params.node.data.product +  " wird schon verwendet!!";
                        setTimeout(() => {
                            this.errMessage = ""
                        }, 2000);
                        
                        return;
                    }
                    this.rowDataDropZone.push(params.node.data)
                },
            };
            params.api.addRowDropZone(dropZone);
        },  

        deleteItem(item){
            console.debug("deleteItem", item)
            let idx =this.products.findIndex(x => x.product == item.product)
            if(idx >= 0)
                this.products.splice(idx,1)
        }   
 
    },

    watch: { 
  
        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal, this.item)
            if(this.isEdit)
                this.$refs.form.validate()
        },

        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal, this.item)
            this.state.valid = this.valid ; 
        }, 

        isEdit: function(newVal) { // watch it
            console.log('Prop changed: isEdit', newVal, this.item)
            if(!newVal)
                this.$refs.form.reset() 
        },    
        
        isVisible: function(newVal) { // watch it
            console.log('Prop changed: isVisible', newVal, this.item)
            // if(newVal)
            //     this.selectedType.type = this.item.type
            if(!this.isEdit)
                this.$refs.form.reset() 
        },          
    },

    mounted() {
        console.debug('mounted', this.item);
        if(this.isEdit)
                this.$refs.form.validate()
    }
}
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>


<style lang="scss">

    .products { @import "~ag-grid-community/styles/ag-theme-alpine.css"; 

        .ag-header-cell-label {
        /*Necessary to allow for text to grow vertically*/
        height: 100%;
        padding: 0 !important;
        }

        .ag-header-cell-label .ag-header-cell-text {
        /*Force the width corresponding at how much width
            we need once the text is laid out vertically*/
        //  width: 55px;
        writing-mode: vertical-lr;
        //  -ms-writing-mode: tb-lr;
        //  line-height: 2em;
        margin-top: 10px;
        }
    }
</style> 


<style lang="scss">


</style>