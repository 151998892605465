<template> 
    <div style="margin-top: 5px;">
             <svg
                v-if="cellValue > 0"
                xmlns="http://www.w3.org/2000/svg"
                baseProfile="tiny"
                version="1.2"
                width="100%"
                height="30px"
                
                
            >
                <rect
                    x="0"
                    y="0"
                    :width="width"
                    height="100%"
                    stroke="transparent"
                    stroke-width="1"
                    fill="green"
                >
                </rect>
            </svg>
        
        <v-row  v-if="cellValue == 0" class="ma-0" align="center" justify="center">
            <v-icon class="mt-1">mdi-close-circle-outline</v-icon>
        </v-row>
        
    </div>
</template>

<script>   

 
export default {

    props: {

    },
    

    data() { 
        return {
            cellValue: null
        }
    },
    
    computed: {
        width(){
            return this.cellValue ? this.cellValue + "%" : "0%"
        }
    },
    
    methods: {

        getValueToDisplay(params) {
            return params.valueFormatted ? params.valueFormatted : params.value;
        },

        getIcon(params) {
            return params.icon ? params.icon : this.icon;
        },
    },
    beforeMount() {
        // this.params contains the cell and row information and is made available to this component at creation time
        // see ICellRendererParams for more details
        console.debug("beforeMount", this.params)
        this.cellValue = this.getValueToDisplay(this.params);
    },    

}
</script>