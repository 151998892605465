<template>
    <v-btn
    class="ml-n2"
        icon
        @click="buttonClicked()"
    >
        <v-icon>{{icon}}</v-icon>
    </v-btn>
</template>

<script>   

 
export default {

    props: {

    },
    

    data() { 
        return {
            cellValue: null
        }
    },
    
    computed: {
        icon(){
            return this.params.icon ? this.params.icon : "mdi-apps";
        }
    },
    
    methods: {
        buttonClicked() {
            //alert(`${this.cellValue} medals won!`);
            // const idx = this.params.data.findIndex(item => item.id === this.cellValue);
            // if(idx)
            //     this.params.data.slice(idx,1)
            if(this.params.clickEvent)
                this.params.clickEvent(this.params.data);
        },

        getValueToDisplay(params) {
            return params.valueFormatted ? params.valueFormatted : params.value;
        },

        getIcon(params) {
            return params.icon ? params.icon : this.icon;
        },
    },
    beforeMount() {
        // this.params contains the cell and row information and is made available to this component at creation time
        // see ICellRendererParams for more details
        console.debug("beforeMount", this.params)
        this.cellValue = this.getValueToDisplay(this.params);
    },    

}
</script>